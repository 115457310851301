export class AuthSignUp {
  static readonly type = '@AuthState.signUp';
  constructor(
    public payload: {
      firstName: string;
      lastName: string;
      email: string;
      password: string;
    },
  ) {}
}

export class AuthLogin {
  static readonly type = '@AuthState.login';
  constructor(
    public payload: {
      email: string;
      password: string;
    },
  ) {}
}

export class AuthGetUserDetail {
  static readonly type = '@AuthState.getUserDetail';
  constructor(public fetchUpdates: boolean = false) {}
}

export class AuthSignOut {
  static readonly type = '@AuthState.signOut';
}

export class AuthSendEmailVerification {
  static readonly type = '@AuthState.sendEmailVerification';
}

export class AuthSendForgotPasswordEmail {
  static readonly type = '@AuthState.sendForgotPasswordEmail';
  constructor(public email: string) {}
}

export class AuthUpdatePassword {
  static readonly type = '@AuthState.updatePassword';
  constructor(
    public currentPassword: string,
    public newPassword: string,
  ) {}
}

export class AuthRegisterUserDevice {
  static readonly type = '@AuthState.registerUserDevice';
}
